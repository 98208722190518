<template>
  <div id="page">
    <div class="layout htop text-white">
      <div class="container">
        <span class="fr">
          <i class="icon-phone-square"></i> 服务热线：{{ footer.tel }}
        </span>
        欢迎访问{{ menus.title }}官方网站！
      </div>
    </div>
    <div class="container">
      <div class="header">
        <div class="logo">
          <a :href="menus.domainname"
            ><img
              referrerpolicy="no-referrer"
              :src="menus.logo"
              :alt="menus.title"
          /></a>
        </div>
        <div class="tad"><img src="@/static/picture/toptxt.png" alt="" /></div>
        <div class="sobox">
          <!-- <form name="formsearch" action="/search/" method="get">
            <div class="input-group padding-little-top">
              <input
                name="keyword"
                type="text"
                id="keyword"
                class="input"
                value=""
                placeholder="输入关键词"
              />
              <span class="addbtn">
                <button type="submit" class="button bg-blue">
                  <i class="icon-search"></i>
                </button>
              </span>
            </div>
          </form> -->
        </div>
      </div>
    </div>
    <div class="layout menuwrap">
      <div class="container">
        <a href="#menu" class="button icon-navicon"></a>
        <div class="menu f14 fb nav-navicon" id="menu">
          <ul>
            <li>
              <router-link to="/">首页</router-link>
            </li>
            <li v-for="(item, index) in tablist" :key="index">
              <div>
                <router-link :to="{}" @click.enter="one(item)">{{
                  item.title
                }}</router-link>
              </div>
              <!-- <div>
                <router-link
                  :to="{
                    path: '/' + item.urlname,
                    state: {},
                    component: () =>
                      import('@/components/list/' + item.listtemplate + '.vue'),
                  }"
                  @click.enter="one(item)"
                  >{{ item.title }}</router-link
                >
              </div> -->

              <ul v-if="item.children.length != 0">
                <li v-for="(item1, index1) in item.children" :key="index1">
                  <router-link
                    :to="{
                      path: '/' + item.urlname + '/' + item1.urlname,
                      query: {},
                      component: () =>
                        import(
                          '@/components/list/' + item1.listtemplate + '.vue'
                        ),
                    }"
                    @click.enter="ones(item, item1)"
                    >{{ item1.title }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <div
      style="
        position: fixed;
        right: 0px;
        z-index: 9999;
        top: 300px;
        display: flex;
        align-items: center;
      "
    >
      <div>
        <img
          src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/new.png"
          referrerpolicy="no-referrer"
          alt=""
          style="width: 120px; height: 120px !important"
        />
        <div style="text-align: center">入会二维码</div>
      </div>
    </div> -->
    <div
      style="
        position: fixed;
        right: 0px;
        z-index: 9999;
        top: 500px;
        display: flex;
        align-items: center;
      "
    >
      <div>
        <img
          src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/hunlian.png"
          referrerpolicy="no-referrer"
          alt=""
          style="width: 120px; height: 120px !important"
        />
        <div style="text-align: center">婚恋服务登记表</div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        right: 0px;
        z-index: 9999;
        top: 700px;
        display: flex;
        align-items: center;
      "
    >
      <div>
        <img
          src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/hongn.png"
          referrerpolicy="no-referrer"
          alt=""
          style="width: 120px; height: 120px !important"
        />
        <div style="text-align: center">红娘志愿者登记表</div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        left: 0px;
        z-index: 9999;
        top: 300px;
        display: flex;
        align-items: center;
      "
    >
      <div>
        <img
          src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/gzjiaxie.jpg"
          referrerpolicy="no-referrer"
          alt=""
          style="width: 120px; height: 120px !important"
        />
        <div style="text-align: center;">家协微信公众号</div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        left: 0px;
        z-index: 9999;
        top: 500px;
        display: flex;
        align-items: center;
      "
    >
      <div>
        <img
          src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/appjiaxie.png"
          referrerpolicy="no-referrer"
          alt=""
          style="width: 120px; height: 120px !important"
        />
        <div style="text-align: center">家协微信小程序</div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        left: 0px;
        z-index: 9999;
        top: 700px;
        display: flex;
        align-items: center;
      "
    >
      <div>
        <img
          src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/new.png"
          referrerpolicy="no-referrer"
          alt=""
          style="width: 120px; height: 120px !important"
        />
        <div style="text-align: center">入会二维码</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import router from "@/router";
// import { createApp } from "vue";
// import App from "../../App.vue";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      tablist: [],
      footer: [],
      menus: [],
    };
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    one(item) {
      console.log("1111111111111111111111111", Math.random());

      //   let a = {
      //     data: JSON.stringify(item),
      //     name: JSON.stringify(item),
      //     a: Math.random(),
      //   };
      localStorage.setItem("cachedData", Math.random());
      this.$router.push({
        path: "/" + item.urlname,
        state: {
          params: {
            data: JSON.stringify(item),
            name: JSON.stringify(item),
            // a: Math.random(),
          },
        },
        // query: {
        //   data: JSON.stringify(item),
        //   name: JSON.stringify(item),
        //   a: Math.random(),
        // },
        component: () =>
          import("@/components/list/" + item.listtemplate + ".vue"),
      });
    },
    ones(item, item1) {
      this.$router.push({
        path: "/" + item.urlname + "/" + item1.urlname,
        state: {
          params: {
            data: JSON.stringify(item1),
            name: JSON.stringify(item),
            name1: JSON.stringify(item1),
            // a: Math.random(),
          },
        },
        // query: {
        //   data: JSON.stringify(item1),
        //   name: JSON.stringify(item),
        //   name1: JSON.stringify(item1),
        //   a: Math.random(),
        // },
        component: () =>
          import("@/components/list/" + item1.listtemplate + ".vue"),
      });
    },
    init() {
      var that = this;
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
          //   "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=51&title=协会"
        )
        .then(function (response) {
          that.tablist = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get("https://s.awhfjt.com/web-sites/familyassociation/bottom")
        .then(function (response) {
          that.footer = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get("https://s.awhfjt.com/web-sites/familyassociation/officialweb")
        .then(function (response) {
          that.menus = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="@/static/css/animate.min.css" />
<style src="@/static/css/pintuer.css" />
<style src="@/static/css/index.css" />
<style src="@/static/css/media.css" />
<style scoped lang="scss">
.menu li {
  display: block;
  &:hover {
    ul {
      display: block !important;
    }
  }
}
</style>
