// import Vue from "vue";
// import Router from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";
import Index from "@/components/index.vue";
import Hangye from "@/components/hangye/index.vue";
// import Lists from "@/components/list/sousuo.vue";
import Lists from "@/components/list/newslist.vue";
// import Listss from "@/components/list/newslists.vue";
import ImagesLists from "@/components/list/imageslist.vue";
import About from "@/components/list/about.vue";
// import { descriptionProps } from "element-plus";
// Vue.use(Router); //使用vue-router

// export default new Router({
//     routes:[
//         {
//             path:"/page1",
//             name:"page1",
//             component:()=>import('./pages/page1'),
//         },
//         {
//             path:'/page2',
//             name:'page2',
//             component:Page2
//         },
//     ]
// })
const routes = [
  {
    path: "/",
    // name: "HelloWorld",
    component: Index,
  },
  {
    path: "/hangye",
    //   name: "HangyeIndex",
    component: Hangye,
    props: true,
  },
  {
    path: "/hangye/pingxuan",
    //   name: "NewsList",
    component: Lists,
    props: true,
  },
  {
    path: "/hangye/rencai",
    //   name: "ImagesList",
    component: ImagesLists,
    props: true,
  },
  {
    path: "/hangye/contact",
    //   name: "AboutIndex",
    component: About,
    props: true,
  },
  {
    path: "/xinxi",
    // name: "xinxi",
    component: Lists,
    props: true,
  },
  {
    path: "/xinxi/yaowen",
    component: Lists,
    props: true,
  },
  {
    path: "/xinxi/tongzhi",
    component: Lists,
    props: true,
  },
  {
    path: "/xinxi/jigou",
    component: Lists,
    props: true,
  },
  {
    path: "/xinxi/dashiji",
    component: Lists,
    props: true,
  },
  {
    path: "/xinxi/fenhui",
    component: Lists,
    props: true,
  },
  //会员
  {
    path: "/huiyuan",
    // name: "huiyuan",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/shenqing",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/liucheng",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/zhangcheng",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/vipmess",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/jiazheng",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/hospital",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/fengcai",
    component: Lists,
    props: true,
  },
  {
    path: "/huiyuan/11",
    component: Lists,
    props: true,
  },
  //
  {
    path: "/down",
    component: Lists,
    props: true,
  },
  //
  {
    path: "/xinwen",
    component: Lists,
    props: true,
  },
  {
    path: "/xinwen/zixun",
    component: Lists,
    props: true,
  },
  {
    path: "/xinwen/zhengce",
    component: Lists,
    props: true,
  },
  //
  {
    path: "/contact",
    component: Lists,
    props: true,
  },
  {
    path: "/contact/jianjie",
    component: Lists,
    props: true,
  },
  {
    path: "/contact/lianxi",
    component: Lists,
    props: true,
  },
  //
  {
    path: "/message",
    component: Lists,
    props: true,
  },
  {
    path: "/message/vipmess",
    component: Lists,
    props: true,
  },
  {
    path: "/message/jiazheng",
    component: Lists,
    props: true,
  },
  {
    path: "/message/hospital",
    component: Lists,
    props: true,
  },
  //
  {
    path: "/fuli",
    component: Lists,
    props: true,
  },
  {
    path: "/chuangye",
    component: Lists,
    props: true,
  },
  {
    path: "/xiehui",
    component: Lists,
    props: true,
  },

  {
    path: "/xiehui/zhanshi",
    component: Lists,
    props: true,
  },
  {
    path: "/xiehui/ziyuan",
    component: Lists,
    props: true,
  },
  //
  {
    path: "/hunlian",
    component: Lists,
    props: true,
  },

  {
    path: "/hunlian/hunlianjj",
    component: Lists,
    props: true,
  },
  {
    path: "/hunlian/hunlians",
    component: Lists,
    props: true,
  },
  {
    path: "/hunlian/hunlianhn",
    component: Lists,
    props: true,
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  mode: "history",
  routes,
});
export default router;
