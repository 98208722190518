<template>
  <div id="page">
    <!-- <Menu /> -->
    <div class="layout crumbwrap">
      <div class="container">
        <div class="crumb">
          <div class="f14 text-gray-light">
            <span class="icon-home text-blue"></span> <a href="/">首页</a> >>
            <a href="/hangye/">协会介绍</a>
          </div>
        </div>
      </div>
    </div>
    <div class="layout tWrap">
      <div class="container f16">
        <div class="line-large">
          <div class="xs3">
            <div class="sidebar side_sb">
              <h3 class="tit text-white text-center f16 fs100">协会介绍</h3>
              <div class="snav">
                <ul>
                  <li>
                    <router-link to="/hangye/pingxuan">组织架构</router-link>
                  </li>
                  <!-- <li><a href="/pingxuan/">组织架构</a></li> -->
                  <li>
                    <router-link to="/hangye/rencai">协会领导</router-link>
                  </li>
                  <li>
                    <router-link to="/hangye/contact">联系我们</router-link>
                  </li>
                  <!-- <li><a href="/rencai/">协会领导</a></li> -->
                </ul>
              </div>
            </div>
            <div class="sideqrcode margin-big-top">
              <img
                src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/logo_phone.3d8fd235.png"
                referrerpolicy="no-referrer"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <!-- -------------------- -->
          <div class="xs9">
            <div class="ctitle">
              <div class="wrap">
                <span class="f16 fs100 text-white">协会介绍</span>
              </div>
            </div>
            <div class="newslist">
              <dl>
                <dd class="date text-gray"><span>15</span>2022-02</dd>
                <dt class="f18 fn text-more">
                  <a href="/rencai/98.html" class="text-black"
                    ><span>协会领导</span></a
                  >
                </dt>
                <dd class="desc text-gray f14">
                  <a href="/rencai/98.html">协会领导</a>
                </dd>
              </dl>
              <dl>
                <dd class="date text-gray"><span>15</span>2022-02</dd>
                <dt class="f18 fn text-more">
                  <a href="/rencai/98.html" class="text-black"
                    ><span>协会领导</span></a
                  >
                </dt>
                <dd class="desc text-gray f14">
                  <a href="/rencai/98.html">协会领导</a>
                </dd>
              </dl>
              <dl>
                <dd class="date text-gray"><span>15</span>2022-02</dd>
                <dt class="f18 fn text-more">
                  <a href="/rencai/98.html" class="text-black"
                    ><span>协会领导</span></a
                  >
                </dt>
                <dd class="desc text-gray f14">
                  <a href="/rencai/98.html">协会领导</a>
                </dd>
              </dl>
              <dl>
                <dd class="date text-gray"><span>15</span>2022-02</dd>
                <dt class="f18 fn text-more">
                  <a href="/rencai/98.html" class="text-black"
                    ><span>协会领导</span></a
                  >
                </dt>
                <dd class="desc text-gray f14">
                  <a href="/rencai/98.html">协会领导</a>
                </dd>
              </dl>
            </div>

            <div class="pagebar">
              <div class="pagination">
                <a
                  class="page-item page-link st"
                  href="javascript:;"
                  title="上一页"
                >
                  《
                </a>
                <a href="/hangye/" class="page-num page-num-current">1</a>
                <a
                  class="page-item page-link"
                  href="javascript:;"
                  title="当前页/总页数"
                  >1/1</a
                >
                <a
                  class="page-item page-link st"
                  href="javascript:;"
                  title="下一页"
                  >>></a
                >
              </div>
            </div>
          </div>
          <!-- -------------------- -->
        </div>
      </div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "HangyeIndex",
  //   components: { Lists },
  props: {
    msg: String,
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    init() {
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation/articles?title=测试&cateid=63&page=1&size=2"
        )
        .then(function (response) {
          console.log("response", response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
