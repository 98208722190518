<template>
  <div id="page">
    <div class="layout footer2 f14">
      <!--<div class="fotlogo text-center"><img src="@/static/picture/1699320509536719.jpg" alt="二维码"> </div>-->
      <div class="fotnav">
        <div class="container">
          <div class="clearfix line-big">
            <dl v-for="(item, index) in tablist" :key="index">
              <dt class="f18 fs100">
                <img src="@/static/picture/fico1.png" alt="协会介绍" />
                <!-- <a @click="ones(item)">{{ item.title }}</a> -->
                <router-link
                  :to="{
                    path: '/' + item.urlname,
                    query: {},
                    component: () =>
                      import('@/components/list/' + item.listtemplate + '.vue'),
                  }"
                  @click.enter="one(item)"
                  >{{ item.title }}</router-link
                >
              </dt>
              <dd class="clearfix" v-if="item.children.length != 0">
                <ul v-for="(item1, index1) in item.children" :key="index1">
                  <li>
                    <!-- <a @click="one(item, item1)">{{ item1.title }}</a> -->
                    <router-link
                      :to="{
                        path: '/' + item.urlname + '/' + item1.urlname,
                        query: {},
                        component: () =>
                          import(
                            '@/components/list/' + item1.listtemplate + '.vue'
                          ),
                      }"
                      @click.enter="ones(item, item1)"
                      >{{ item1.title }}</router-link
                    >
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="copyright layout clearfix">
        <div class="container">
          <div class="text-white text-center">
            <span>地址：{{ footer.addr }}</span>
            <span>电话：{{ footer.tel }}</span>
            <span>E-mail：{{ footer.email }}</span
            ><span></span
            ><span
              ><a :href="footer.recordurl" target="_blank" rel="nofollow">{{
                footer.record
              }}</a></span
            ><br />
            友情链接：<span
              ><a :href="footer.friendchain" target="_blank" rel="nofollow">{{
                footer.friendname
              }}</a></span
            >
            <span
              ><a :href="footer.ecompurl" target="_blank" rel="nofollow">{{
                footer.ecompname
              }}</a></span
            >
            <span
              ><a :href="footer.jzpurl" target="_blank" rel="nofollow">{{
                footer.jzname
              }}</a></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  data() {
    return {
      footer: [],
      tablist: [],
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    one(item) {
      this.$router.push({
        path: "/" + item.urlname,
        state: {
          params: {
            data: JSON.stringify(item),
            name: JSON.stringify(item),
            a: Math.random(),
          },
        },
        // query: {
        //   data: JSON.stringify(item),
        //   name: JSON.stringify(item),
        //   a: Math.random(),
        // },
        component: () =>
          import("@/components/list/" + item.listtemplate + ".vue"),
      });
    },
    ones(item, item1) {
      this.$router.push({
        path: "/" + item.urlname + "/" + item1.urlname,
        state: {
          params: {
            data: JSON.stringify(item1),
            name: JSON.stringify(item),
            name1: JSON.stringify(item1),
            a: Math.random(),
          },
        },
        // query: {
        //   data: JSON.stringify(item1),
        //   name: JSON.stringify(item),
        //   name1: JSON.stringify(item1),
        //   a: Math.random(),
        // },
        component: () =>
          import("@/components/list/" + item1.listtemplate + ".vue"),
      });
    },
    init() {
      var that = this;
      axios
        .get("https://s.awhfjt.com/web-sites/familyassociation/bottom")
        .then(function (response) {
          that.footer = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          that.tablist = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="@/static/css/animate.min.css" />
<style src="@/static/css/pintuer.css" />
<style src="@/static/css/index.css" />
<style src="@/static/css/media.css" />
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  cursor: pointer;
  color: #42b983;
}
</style>
