<template>
  <div id="app">
    <Menu />
    <router-view :key="$route.fullPath" />
    <Footer />
    <!-- <Index msg="Welcome to Your Vue.js App" /> -->
  </div>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
</template>

<script>
// import Index from "@/components/hangye";
import Menu from "@/components/common/menu.vue";
import Footer from "@/components/common/footers.vue";
import axios from "axios";
// import { useRoute } from "vue-router";
export default {
  name: "App",
  components: {
    // Index,
    Menu,
    Footer,
  },
  //   data() {
  //     return {
  //       key: useRoute().fullPath,
  //     };
  //   },
  //   watch: {
  //     key: function () {
  //       console.log("ceshi", useRoute().fullPath);
  //     },
  //   },
  mounted() {
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/addvisits?platform=1"
      )
      //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
      .then(function (response) {
        console.log("家协官网累计量+1", response);

        // that.img = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    // axios
    //   .get(
    //     "http://local.s.awhfjt.com/web-sites/familyassociation/selectvisits?platform=1"
    //   )
    //   //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
    //   .then(function (response) {
    //     console.log("查询家协官网累计量", response);
    //     // that.img = response.data;
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  },
};
</script>
<style src="@/static/css/animate.min.css" />
<style src="@/static/css/pintuer.css" />
<style src="@/static/css/index.css" />
<style src="@/static/css/media.css" />
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
