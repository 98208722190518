<template>
  <div id="page">
    <!-- <Menu /> -->
    <div class="layout crumbwrap">
      <div class="container">
        <div class="crumb">
          <div class="f14 text-gray-light" v-if="breadcrumbs">
            <span class="icon-home text-blue"></span>
            <router-link to="/">首页</router-link> >>
            <!-- <a href="/huiyuan/">{{ breadcrumb1.title }}</a> -->
            <router-link
              :to="{
                path: '/' + breadcrumb1.urlname,
                query: {
                  data: JSON.stringify(breadcrumb1),
                  name: JSON.stringify(breadcrumb1),
                },
                component: () =>
                  import(
                    '@/components/list/' + breadcrumb1.listtemplate + '.vue'
                  ),
              }"
              >{{ breadcrumb1.title }}</router-link
            >
            >>
            <!-- <a href="/fengcai/">{{ breadcrumb2.title }}</a> -->
            <router-link
              :to="{
                path: '/' + breadcrumb1.urlname + '/' + breadcrumb2.urlname,
                query: {
                  data: JSON.stringify(breadcrumb2),
                  name: JSON.stringify(breadcrumb1),
                  name1: JSON.stringify(breadcrumb2),
                },
                component: () =>
                  import(
                    '@/components/list/' + breadcrumb2.listtemplate + '.vue'
                  ),
              }"
              >{{ breadcrumb2.title }}</router-link
            >
          </div>
          <div class="f14 text-gray-light" v-else>
            <span class="icon-home text-blue"></span>
            <router-link to="/">首页</router-link> >>
            <router-link
              :to="{
                path: '/' + breadcrumb1.urlname,
                query: {
                  data: JSON.stringify(breadcrumb1),
                  name: JSON.stringify(breadcrumb1),
                },
                component: () =>
                  import(
                    '@/components/list/' + breadcrumb1.listtemplate + '.vue'
                  ),
              }"
              >{{ breadcrumb1.title }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="layout tWrap">
      <div class="container f16">
        <div class="line-large">
          <div class="xs3">
            <div class="sidebar side_sb">
              <h3 class="tit text-white text-center f16 fs100">
                {{ leftlist.title }}
              </h3>
              <div class="snav">
                <ul v-for="(item, index) in leftlist.children" :key="index">
                  <li>
                    <!-- <router-link to="/hangye/pingxuan">组织架构</router-link> -->
                    <router-link
                      :to="{
                        path: '/' + leftlist.urlname + '/' + item.urlname,
                        query: {
                          data: JSON.stringify(item),
                          name: JSON.stringify(leftlist),
                          name1: JSON.stringify(item),
                        },
                        component: () =>
                          import(
                            '@/components/list/' + item.listtemplate + '.vue'
                          ),
                      }"
                      >{{ item.title }}</router-link
                    >
                  </li>
                  <!-- <li><a href="/pingxuan/">组织架构</a></li> -->
                  <!-- <li>
                        <router-link to="/hangye/rencai">协会领导</router-link>
                      </li>
                      <li>
                        <router-link to="/hangye/contact">联系我们</router-link>
                      </li> -->
                  <!-- <li><a href="/rencai/">协会领导</a></li> -->
                </ul>
              </div>
            </div>
            <div class="sideqrcode margin-big-top">
              <img
                src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/logo_phone.3d8fd235.png"
                referrerpolicy="no-referrer"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
          <!-- -------------------- -->

          <div class="xs9">
            <div class="ctitle">
              <div class="wrap">
                <span class="f16 fs100 text-white">{{ centers.title }}</span>
              </div>
            </div>
            <div class="content page30" id="maximg" v-if="content">
              <span v-html="content"></span>
            </div>
            <div v-else style="text-align: center">暂无数据</div>
          </div>
          <!-- -------------------- -->
        </div>
      </div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>
<script>
import axios from "axios";
import { onMounted, reactive, toRefs, nextTick, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "HangyeIndex",
  //   components: { Lists },
  props: {
    msg: String,
  },
  setup() {
    const $router = useRoute();
    const state = reactive({
      flielist: [],
      breadcrumbs: false,
      breadcrumb1: [],
      breadcrumb2: [],
      leftlist: [],
      centers: [],
      content: "",
    });
    onMounted(() => {
      nextTick(() => {
        // 监听布局配置弹窗点击打开
      });
    });
    watch(() => {
      if ($router.query.data) {
        state.leftlist = JSON.parse($router.query.name);
        state.centers = JSON.parse($router.query.data);
        let a;
        let b;
        if ($router.query.name1) {
          a = JSON.parse($router.query.name);
          b = JSON.parse($router.query.name1);
          state.breadcrumbs = true;
          state.breadcrumb1 = a;
          state.breadcrumb2 = b;
        } else {
          a = JSON.parse($router.query.name);
          state.breadcrumbs = false;
          state.breadcrumb1 = a;
        }
      }
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=" +
            state.centers.id
        )
        .then(function (response) {
          state.flielist = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation/articlesinfo?id=" +
            state.centers.id
        )
        .then(function (response) {
          state.content = response.data[0].data.content;
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    function init() {
      //   var that = this;
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation/articles?title=测试&cateid=63&page=1&size=2"
        )
        .then(function (response) {
          state.flielist = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
/* h3 {
        margin: 40px 0 0;
      }
      ul {
        list-style-type: none;
        padding: 0;
      }
      li {
        display: inline-block;
        margin: 0 10px;
      }
      a {
        color: #42b983;
      } */
</style>
