import { createApp } from "vue";
// import Vue from "vue";
// import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-plus";
import "element-plus/dist/index.css";
// import BaiduMap from "vue-baidu-map-v3";
import BaiduMap from "vue-baidu-map-3x";
// import BaiduMap from "vue-baidu-map";
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: "cko6lEVEy689vpBvGoiYvPaMtGnlGGBn",
// });
import $ from "jquery";
localStorage.setItem("cachedData", 0);
window.jQuery = $;
window.$ = $;
createApp(App)
  .use(router)
  .use(ElementUI)
  .use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: "cko6lEVEy689vpBvGoiYvPaMtGnlGGBn",
  })
  //   .component(BaiduMap, {
  //     // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  //     ak: "cko6lEVEy689vpBvGoiYvPaMtGnlGGBn",
  //   })
  .mount("#app");
// new Vue({
//   render: (h) => h(App),
// }).$mount("#app");
