<template>
  <div id="page">
    <div class="block text-center layout banner">
      <el-carousel
        style="height: 500px !important; --arrow-display: none"
        class="mmmm"
      >
        <el-carousel-item
          v-for="(item, index) in img"
          :key="index"
          style="background-color: #000000 !important; height: 500px !important"
        >
          <img
            :src="item.urlname"
            referrerpolicy="no-referrer"
            alt=""
            style="width: auto; height: 500px !important"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 头条新闻、协会要闻、通知公告、小轮播图 -->
    <div class="container">
      <!-- 滚动 -->
      <!-- <div
        class="mobile-marquee"
        @mouseenter="stopScroll"
        @mouseleave="startScroll"
      >
        <div class="mobile-marquee-wrapper" ref="wrapper">
          <div
            v-if="lunboshow"
            class="mobile-marquee-text"
            ref="text"
            :style="{ left: textLeft, transition: textTransition }"
          >
            {{ text }}
          </div>
        </div>
      </div> -->
      <!-- <vue3-seamless-scroll
        :list="textlist"
        class="seamless_scroll"
        :hover="true"
        :step="0.2"
        :limitScrollNum="2"
        :direction="'0'"
      >
        <div
          class="seamless_scroll_item"
          v-for="(item, index) in textlist"
          :key="index"
        >
          <img
            src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/redian.png"
            style="width: 20px; height: 20px; margin-top: -4px"
          />{{ item.text }}
        </div>
      </vue3-seamless-scroll> -->
      <div class="ipart1 clearfix line-big">
        <div style="position: absolute; left: 0">
          <!-- <Maps></Maps> -->
          <!-- <baidu-map :center="center" :zoom="zoom"></baidu-map> -->
        </div>
        <div class="xm4 xs12">
          <div class="topnews">
            <h3 class="itittop"><span>头条新闻</span></h3>

            <div class="con">
              <h3 class="fb f16 text-more text-center margin-bottom">
                {{ toutiao.title }}
              </h3>
              <div class="desc text-justify text-gray text-indent f14">
                {{ toutiao.description }}···
                <router-link :to="{}" @click.enter="leftclick(57, 61)"
                  >{详情}</router-link
                >
              </div>
            </div>
            <div class="con">
              <h3 class="fb f16 text-more text-center margin-bottom">
                {{ toutiaos.title }}
              </h3>
              <div class="desc text-justify text-gray text-indent f14">
                {{ toutiaos.description }}···
                <router-link :to="{}" @click.enter="leftclickt(57, 61)"
                  >{详情}</router-link
                >
              </div>
            </div>
          </div>

          <!-- <div class="yhnews">
            <div class="ntit">
              <router-link
                :to="{
                  path: '/xinxi/yaowen',
                  query: {},
                  component: () => import('@/components/list/newslist.vue'),
                }"
                @click.enter="leftclick1(51, 52)"
                ><span class="f16 fb text-blue">协会要闻</span></router-link
              >
            </div>
            <div class="inews">
              <ul class="padding f14">
                <li
                  class="text-more"
                  v-for="(item, index) in yaowen"
                  :key="index"
                >
                  <span class="icon-angle-double-right text-yellow"></span>
                  <router-link
                    :to="{
                      path: '/xinxi/yaowen',
                      query: {},
                      component: () => import('@/components/list/newslist.vue'),
                    }"
                    @click.enter="leftclick2(51, 52, item.id)"
                    >{{ item.title }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div> -->
        </div>
        <div class="xm5 xs12" style="width: 33.33333333%">
          <div class="block text-center">
            <el-carousel style="height: 308px !important">
              <el-carousel-item
                v-for="(item, index) in slidess"
                :key="index"
                style="height: 308px !important"
              >
                <div>
                  <router-link
                    :to="{}"
                    @click.enter="leftclick3(57, 61, item.id)"
                    ><img
                      referrerpolicy="no-referrer"
                      style="width: 100%; height: 308px"
                      :src="item.cover"
                      :alt="item.title"
                    />
                    <div
                      class="text-more"
                      style="
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        padding: 5px 100px 5px 10px;
                        background: rgba(0, 0, 0, 0.6);
                        line-height: 30px;
                        color: #fff;
                        text-align: left;
                      "
                    >
                      {{ item.title }}
                    </div></router-link
                  >
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="xm3 xs12" style="width: 33.33333333%">
          <div class="yhnews">
            <div class="ntit">
              <router-link :to="{}" @click.enter="leftclick1(51, 53)"
                ><span class="f16 fb text-blue">通知公告</span></router-link
              >
            </div>
            <div class="inews">
              <ul class="padding f14">
                <li
                  class="text-more"
                  v-for="(item, index) in gonggao"
                  :key="index"
                >
                  <span class="icon-angle-double-right text-yellow"></span>
                  <router-link
                    :to="{}"
                    @click.enter="leftclick2(51, 53, item.id)"
                    >{{ item.title }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 协会动态、优惠券、企业风采 -->
    <div class="container newpart">
      <div class="line-big">
        <div class="xs4">
          <div class="itittop2">
            <router-link :to="{}" @click.enter="leftclick11(51, 54)"
              ><span class="f18" style="color: #ffffff"
                >协会动态</span
              ></router-link
            >
          </div>
          <div class="tab f14" data-toggle="hover">
            <div class="tab-body">
              <div class="tab-panel active" id="tab-38">
                <ul class="f14">
                  <li v-for="(item, index) in xiehui" :key="index">
                    <router-link
                      class="text-more"
                      :to="{}"
                      @click.enter="leftclick2(51, 54, item.id)"
                      ><span class="date">{{ item.year_month }}</span
                      >{{ item.title }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="xs4">
          <div class="itittop2">
            <router-link :to="{}" @click.enter="fuliclick1(74)"
              ><span class="f18" style="color: #ffffff"
                >优惠券</span
              ></router-link
            >
          </div>
          <div class="tab f14" data-toggle="hover">
            <div class="tab-body">
              <div class="tab-panel active" id="tab-38">
                <ul class="f14">
                  <li v-for="(item, index) in fuliq" :key="index">
                    <router-link
                      class="text-more"
                      :to="{}"
                      @click.enter="fuliclick1(74)"
                    >
                      <span class="date">{{ item.expiretime }}过期</span
                      ><img
                        src="https://awhf-oss-img.oss-cn-beijing.aliyuncs.com/homeassociation/redian.png"
                        style="width: 20px; height: 20px; margin-top: -4px"
                      />{{ item.cname }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="xs4">
          <div class="itittop2">
            <router-link :to="{}" @click.enter="leftclick11(51, 55)"
              ><span class="f18" style="color: #ffffff"
                >政策法规</span
              ></router-link
            >
          </div>
          <div class="tab f14" data-toggle="hover">
            <div class="tab-body">
              <div class="tab-panel active" id="tab-38">
                <ul class="f14">
                  <li v-for="(item, index) in zhengce" :key="index">
                    <router-link
                      class="text-more"
                      :to="{}"
                      @click.enter="leftclick2(51, 55, item.id)"
                      ><span class="date">{{ item.year_month }}</span
                      >{{ item.title }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 会员服务 -->
    <div class="container">
      <div class="ipart2 clearfix margin-large-bottom">
        <h3 class="itittop f18 text-blue margin-big-bottom">
          <span>会员服务</span>
        </h3>
        <div class="line-big clearfix margin-big-bottom xlink">
          <div
            class="xs3 xl6"
            v-for="(item, index) in tablist.children"
            :key="index"
            style="width: 33.33333333%; margin-top: 20px"
          >
            <li class="bg-blue">
              <router-link
                :to="{
                  path: '/' + tablist.urlname + '/' + item.urlname,
                  query: {
                    data: JSON.stringify(item),
                    name: JSON.stringify(tablist),
                    name1: JSON.stringify(item),
                  },
                  component: () =>
                    import('@/components/list/' + item.listtemplate + '.vue'),
                }"
                @click.enter="huiyuanclick(item, tablist)"
                ><i :class="tabclass[index]"></i>{{ item.title }}</router-link
              >
            </li>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="ipart3 clearfix margin-big-bottom">
        <div class="line-big"></div>
      </div>
    </div>
    <!-- 会员风采 -->
    <div class="container">
      <div class="ipart5 clearfix margin-large-bottom">
        <h3 class="itittop f18 text-blue"><span>会员风采</span></h3>
        <div class="con clearfix" style="padding-top: 0">
          <div class="mslide">
            <div class="bd">
              <el-carousel
                indicator-position="none"
                style="height: 250px !important"
              >
                <el-carousel-item
                  v-for="(item, index) in listTemp"
                  :key="index"
                  style="background: #ffffff00; height: 250px !important"
                >
                  <div style="margin-left: 7%; margin-top: 6%">
                    <ul
                      class="block text-center"
                      style="width: 100%"
                      v-for="o in item"
                      :key="o"
                    >
                      <router-link
                        :to="{}"
                        @click.enter="leftclick4(57, 70, o.id)"
                        ><li>
                          <img
                            referrerpolicy="no-referrer"
                            style="height: 137px"
                            :src="o.cover"
                            :alt="o.title"
                          />
                          <div class="text-more f14">{{ o.title }}</div>
                        </li></router-link
                      >
                    </ul>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <meta name="referrer" content="no-referrer" />
  </div>
</template>
<script>
import axios from "axios";
import router from "@/router";
import { nextTick } from "vue";
// import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
// import Maps from "@/components/common/map.vue";
// import BaiduMap from "vue-baidu-map/components/map/Map.vue";
// import { onMounted, onUnmounted, ref } from "vue";
export default {
  name: "HelloWorld",
  components: {
    // Maps,
    // Vue3SeamlessScroll,
  },
  props: {
    slides: {
      type: Array,
      default: () => [
        {
          hrefUrl: "/category/1013001",
          id: "18",
          imgUrl:
            "https://img2.baidu.com/it/u=1203241971,3035411972&fm=253&fmt=auto&app=138&f=PNG?w=1371&h=500",
          type: "1",
        },
        {
          hrefUrl: "/category/1005000",
          id: "20",
          imgUrl:
            "https://img2.baidu.com/it/u=3036350543,2417745082&fm=253&fmt=auto&app=138&f=JPEG?w=1600&h=500",
          type: "1",
        },
        {
          hrefUrl: "/category/1013001",
          id: "19",
          imgUrl:
            "https://img1.baidu.com/it/u=1935637157,131697988&fm=253&fmt=auto&app=138&f=JPEG?w=1714&h=500",
          type: "1",
        },
      ],
    },
    slides1: {
      type: Array,
      default: () => [
        {
          hrefUrl: "/category/1013001",
          id: "18",
          imgUrl:
            "https://img2.baidu.com/it/u=1203241971,3035411972&fm=253&fmt=auto&app=138&f=PNG?w=1371&h=500",
          type: "1",
        },
        {
          hrefUrl: "/category/1005000",
          id: "20",
          imgUrl:
            "https://img2.baidu.com/it/u=3036350543,2417745082&fm=253&fmt=auto&app=138&f=JPEG?w=1600&h=500",
          type: "1",
        },
        {
          hrefUrl: "/category/1013001",
          id: "19",
          imgUrl:
            "https://img1.baidu.com/it/u=1935637157,131697988&fm=253&fmt=auto&app=138&f=JPEG?w=1714&h=500",
          type: "1",
        },
      ],
    },
  },
  data() {
    return {
      img: [],
      slidess: [],
      slidessdanwei: [],
      tablist: [],
      toutiaoid: [],
      toutiao: [],
      toutiaoids: [],
      toutiaos: [],
      yaowen: [],
      gonggao: [],
      xiehui: [],
      zhengce: [],
      qiye: [],
      fuliq: [],
      tabclass: [
        "icon-check-square-o f30",
        "icon-envelope-o f30",
        "icon-stack-overflow f30",
        "icon-group f30",
        "icon-briefcase f30",
        "icon-flask f30",
      ],
      //跑马灯
      text1: "",
      text2: "",
      text: "",
      textlist: [
        {
          id: 1,
          text: "",
        },
        {
          id: 2,
          text: "",
        },
      ],
      url: "",
      textLeft: "",
      textTransition: "",
      intervalId: null,
      //------
      wrapperWidth: "",
      textWidth: "",
      transTime: "",
      againTime: "",
      lunboshow: false,
    };
  },
  mounted() {
    var that = this;
    // axios
    //   .get("https://s.awhfjt.com/web-sites/familyassociation/addrinstitution")
    //   //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
    //   .then(function (response) {
    //     // console.log("区域汇总", response.data);
    //     //单位unit
    //     let p = "";
    //     for (let a in response.data.unit) {
    //       p += a + ":" + response.data.unit[a] + "家,";
    //     }
    //     p = p.replace(/,\s*$/, "。");
    //     //个人person
    //     let r = "";
    //     for (let b in response.data.person) {
    //       r += b + ":" + response.data.person[b] + "人,";
    //     }
    //     r = r.replace(/,\s*$/, "；");
    //     // if(response.data.person.length != 0 &&response.data.unit.length != 0){
    //     that.text = "个人会员——    " + r + "             单位会员——    " + p;
    //     that.text1 = "个人会员——    " + r;
    //     that.text2 = "单位会员——    " + p;
    //     // }

    //     that.textlist[0].text = r;
    //     that.textlist[1].text = p;
    //     that.textlist = ref(that.textlist);
    //     if (that.text) {
    //       //   that.startScroll();
    //       that.marquee();
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    //婚恋滚动
    // axios
    //   .get("https://s.awhfjt.com/web-sites/familyassociation/marriage")
    //   .then(function (response) {
    //     console.log("婚恋滚动", response.data);
    //     // let r = "";
    //     // for (let b in response.data) {
    //     //   axios
    //     //     .get(
    //     //       "https://s.awhfjt.com/web-sites/familyassociation/marriageinfo?mid=" +
    //     //         response.data[b].id
    //     //       // "http://local.s.awhfjt.com/web-sites/familyassociation/marriageinfo?mid=" +
    //     //       //   row.id
    //     //     )
    //     //     .then(function (responses) {
    //     //       //   console.log("婚恋", responses.data);
    //     //       let a = responses.data;
    //     //       let sex = a.sex == 1 ? "男" : "女";
    //     //       r +=
    //     //         // "姓名:" +
    //     //         // a.cname +
    //     //         // "," +
    //     //         "性别:" +
    //     //         sex +
    //     //         "," +
    //     //         "年龄:" +
    //     //         a.age +
    //     //         "岁," +
    //     //         "身高:" +
    //     //         a.height +
    //     //         "米," +
    //     //         "体重:" +
    //     //         a.weight +
    //     //         "kg," +
    //     //         "学历:" +
    //     //         a.qualification +
    //     //         "," +
    //     //         "毕业学校:" +
    //     //         a.graduationschool +
    //     //         "。";
    //     //       that.text = "婚恋服务申请人信息：" + r;
    //     //       let c = parseInt(b) + 1;

    //     //       if (c == response.data.length) {
    //     //         if (that.text) {
    //     //           that.lunboshow = true;
    //     //           that.marquee();
    //     //         }
    //     //       }
    //     //     })
    //     //     .catch(function (error) {
    //     //       console.log(error);
    //     //     });
    //     // }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    //大轮播图
    axios
      .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
      //   .get("https://s.awhfjt.com/web-sites/familyassociation/carouselchart")
      .then(function (response) {
        that.img = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    //新闻头条 toutiao
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=61"
      )
      .then(function (response) {
        axios
          .get(
            "https://s.awhfjt.com/web-sites/familyassociation/articlesinfo?id=" +
              response.data[0].id
          )
          .then(function (responses) {
            that.toutiaoid = responses.data[0];

            that.toutiao = responses.data[0].data;
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
    //新闻头条 toutiaos
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=61"
      )
      .then(function (response) {
        axios
          .get(
            "https://s.awhfjt.com/web-sites/familyassociation/articlesinfo?id=" +
              response.data[1].id
          )
          .then(function (responses) {
            that.toutiaoids = responses.data[0];

            that.toutiaos = responses.data[0].data;
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

    //协会要闻
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=52"
      )
      .then(function (response) {
        that.yaowen = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    //通知公告
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=53"
      )
      .then(function (response) {
        that.gonggao = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    //协会动态
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=54"
      )
      .then(function (response) {
        that.xiehui = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    //政策法规
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=55"
      )
      .then(function (response) {
        that.zhengce = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    //福利区
    axios
      .get("https://s.awhfjt.com/web-sites/familyassociation/getcoupon")
      .then(function (response) {
        that.fuliq = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    //会员服务
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
      )
      .then(function (response) {
        response.data.map((item) => {
          if (item.id == "57") {
            that.tablist = item;
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    //会员风采
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/articles?cateid=61"
      )
      .then(function (response) {
        let lunbo = [];
        for (let p in response.data) {
          if (p < 5) {
            lunbo.push(response.data[p]);
          }
        }
        that.slidess = lunbo;
      })
      .catch(function (error) {
        console.log(error);
      });
    //单位会员
    axios
      .get(
        "https://s.awhfjt.com/web-sites/familyassociation/institution?viptype=1"
      )
      .then(function (response) {
        let lunbo = [];
        for (let p in response.data) {
          //   if (p < 5) {
          lunbo.push(response.data[p]);
          //   }
        }
        that.slidessdanwei = lunbo;
        // console.log("会员单位", that.slidessdanwei);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    huiyuanclick(item, tablist) {
      router.push({
        path: "/" + tablist.urlname + "/" + item.urlname,
        state: {
          params: {
            data: JSON.stringify(item),
            name: JSON.stringify(tablist),
            name1: JSON.stringify(item),
          },
        },
        // query: {
        //   data: JSON.stringify(item),
        //   name: JSON.stringify(tablist),
        //   name1: JSON.stringify(item),
        // },
        component: () =>
          import("@/components/list/" + item.listtemplate + ".vue"),
      });
    },
    fuliclick1(res) {
      let a = [];
      let b = [];
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              b = item;
              router.push({
                path: "/fuli",
                state: {
                  params: {
                    data: JSON.stringify(b),
                    name: JSON.stringify(a),
                  },
                },
                component: () => import("@/components/list/newslist.vue"),

                // query: {
                //   data: JSON.stringify(b),
                //   name: JSON.stringify(a),
                //   //   name1: JSON.stringify(b),
                //   //   type: "about",
                //   //   id: that.toutiaoid.id,
                // },
              });
              //   axios
              //     .get(
              //       "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
              //         res
              //     )
              //     .then(function (responses) {
              //       responses.data.map((item1) => {
              //         if (item1.id == e) {
              //           b = item1;
              //           router.push({
              //             query: {
              //               data: JSON.stringify(b),
              //               name: JSON.stringify(a),
              //             //   name1: JSON.stringify(b),
              //               //   type: "about",
              //               //   id: that.toutiaoid.id,
              //             },
              //           });
              //         }
              //       });
              //     })
              //     .catch(function (error) {
              //       console.log(error);
              //     });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leftclick1(res, e) {
      let a = [];
      let b = [];
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              axios
                .get(
                  "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
                    res
                )
                .then(function (responses) {
                  responses.data.map((item1) => {
                    if (item1.id == e) {
                      b = item1;
                      router.push({
                        path: "/xinxi/tongzhi",
                        state: {
                          params: {
                            data: JSON.stringify(b),
                            name: JSON.stringify(a),
                            name1: JSON.stringify(b),
                          },
                        },
                        component: () =>
                          import("@/components/list/newslist.vue"),

                        // query: {
                        //   data: JSON.stringify(b),
                        //   name: JSON.stringify(a),
                        //   name1: JSON.stringify(b),
                        //   //   type: "about",
                        //   //   id: that.toutiaoid.id,
                        // },
                      });
                    }
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leftclick11(res, e) {
      let a = [];
      let b = [];
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              axios
                .get(
                  "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
                    res
                )
                .then(function (responses) {
                  responses.data.map((item1) => {
                    if (item1.id == e) {
                      b = item1;
                      router.push({
                        path: "/xinxi/jigou",
                        state: {
                          params: {
                            data: JSON.stringify(b),
                            name: JSON.stringify(a),
                            name1: JSON.stringify(b),
                          },
                        },
                        component: () =>
                          import("@/components/list/newslist.vue"),
                        // query: {
                        //   data: JSON.stringify(b),
                        //   name: JSON.stringify(a),
                        //   name1: JSON.stringify(b),
                        //   //   type: "about",
                        //   //   id: that.toutiaoid.id,
                        // },
                      });
                    }
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leftclick(res, e) {
      let a = [];
      let b = [];
      var that = this;
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              axios
                .get(
                  "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
                    res
                )
                .then(function (responses) {
                  responses.data.map((item1) => {
                    if (item1.id == e) {
                      b = item1;
                      router.push({
                        path: "/huiyuan/fengcai",
                        state: {
                          params: {
                            data: JSON.stringify(b),
                            name: JSON.stringify(a),
                            name1: JSON.stringify(b),
                            type: "about",
                            id: that.toutiaoid.id,
                          },
                        },
                        component: () =>
                          import("@/components/list/newslist.vue"),
                        // query: {
                        //   data: JSON.stringify(b),
                        //   name: JSON.stringify(a),
                        //   name1: JSON.stringify(b),
                        //   type: "about",
                        //   id: that.toutiaoid.id,
                        // },
                      });
                    }
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leftclickt(res, e) {
      let a = [];
      let b = [];
      var that = this;
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              axios
                .get(
                  "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
                    res
                )
                .then(function (responses) {
                  responses.data.map((item1) => {
                    if (item1.id == e) {
                      b = item1;
                      router.push({
                        path: "/huiyuan/fengcai",
                        state: {
                          params: {
                            data: JSON.stringify(b),
                            name: JSON.stringify(a),
                            name1: JSON.stringify(b),
                            type: "about",
                            id: that.toutiaoids.id,
                          },
                        },
                        component: () =>
                          import("@/components/list/newslist.vue"),

                        // query: {
                        //   data: JSON.stringify(b),
                        //   name: JSON.stringify(a),
                        //   name1: JSON.stringify(b),
                        //   type: "about",
                        //   id: that.toutiaoids.id,
                        // },
                      });
                    }
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leftclick2(res, e, id) {
      let a = [];
      let b = [];
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              axios
                .get(
                  "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
                    res
                )
                .then(function (responses) {
                  responses.data.map((item1) => {
                    if (item1.id == e) {
                      b = item1;
                      router.push({
                        path: "/xinxi/tongzhi",
                        state: {
                          params: {
                            data: JSON.stringify(b),
                            name: JSON.stringify(a),
                            name1: JSON.stringify(b),
                            type: "about",
                            id: id,
                          },
                        },
                        component: () =>
                          import("@/components/list/newslist.vue"),

                        // query: {
                        //   data: JSON.stringify(b),
                        //   name: JSON.stringify(a),
                        //   name1: JSON.stringify(b),
                        //   type: "about",
                        //   id: id,
                        // },
                      });
                    }
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leftclick3(res, e, id) {
      let a = [];
      let b = [];
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              axios
                .get(
                  "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
                    res
                )
                .then(function (responses) {
                  responses.data.map((item1) => {
                    if (item1.id == e) {
                      b = item1;
                      router.push({
                        path: "/huiyuan/fengcai",
                        state: {
                          params: {
                            data: JSON.stringify(b),
                            name: JSON.stringify(a),
                            name1: JSON.stringify(b),
                            type: "about",
                            id: id,
                          },
                        },
                        component: () =>
                          import("@/components/list/newslist.vue"),

                        // query: {
                        //   data: JSON.stringify(b),
                        //   name: JSON.stringify(a),
                        //   name1: JSON.stringify(b),
                        //   type: "about",
                        //   id: id,
                        // },
                      });
                    }
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leftclick4(res, e, id) {
      let a = [];
      let b = [];
      axios
        .get(
          "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate"
        )
        .then(function (response) {
          response.data.map((item) => {
            if (item.id == res) {
              a = item;
              axios
                .get(
                  "https://s.awhfjt.com/web-sites/familyassociation-articles-cate/querycate?cateid=" +
                    res
                )
                .then(function (responses) {
                  responses.data.map((item1) => {
                    if (item1.id == e) {
                      b = item1;
                      router.push({
                        path: "/huiyuan/vipmess",
                        state: {
                          params: {
                            data: JSON.stringify(b),
                            name: JSON.stringify(a),
                            name1: JSON.stringify(b),
                            type: "about",
                            id: id,
                          },
                        },
                        component: () =>
                          import("@/components/list/newslist.vue"),

                        // query: {
                        //   data: JSON.stringify(b),
                        //   name: JSON.stringify(a),
                        //   name1: JSON.stringify(b),
                        //   type: "about",
                        //   id: id,
                        // },
                      });
                    }
                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 跑马灯运作
    // marquee() {
    //   nextTick(() => {
    //     // 监听布局配置弹窗点击打开
    //     console.log("待定", this.$refs.wrapper.clientWidth);
    //     // const _this = this;
    //     this.wrapperWidth = this.$refs.wrapper.clientWidth; // 容器的总宽度
    //     this.textWidth = this.$refs.text.clientWidth; // 文本的总宽度
    //     this.transTime = this.textWidth / 20; // 根据文本宽度设置过渡时间
    //     this.againTime = this.transTime * 1000 + 1000; // 重新开始滚动时间计算(动态)

    //     this.textLeft = this.wrapperWidth + "px"; // 开始滚动前设定文本在容器最右侧以外

    //     setTimeout(() => {
    //       this.textRoll();
    //     }, 0);
    //     //     // 监听布局配置弹窗点击打开
    //     //     const _this = this;
    //     //     const wrapperWidth = this.$refs.wrapper.clientWidth; // 容器的总宽度
    //     //     const textWidth = this.$refs.text.clientWidth; // 文本的总宽度
    //     //     console.log("开始");
    //     //     console.log("容器的总宽度", wrapperWidth);
    //     //     console.log("文本的总宽度", textWidth);
    //     //     const transTime = textWidth / 20; // 根据文本宽度设置过渡时间
    //     //     const againTime = transTime * 1000 + 1000; // 重新开始滚动时间计算(动态)
    //     //     this.textLeft = wrapperWidth + "px"; // 开始滚动前设定文本在容器最右侧以外
    //     //     setTimeout(() => {
    //     //       textRoll();
    //     //     }, 0);
    //     //     function textRoll() {
    //     //       _this.textTransition = "left " + transTime + "s linear"; // 滚动前绑定过渡属性
    //     //       _this.textLeft = -textWidth + "px"; // 向容器最左移动
    //     //       setTimeout(() => {
    //     //         // 还原文本位置
    //     //         _this.textTransition = "none"; // 还原前需清除过渡
    //     //         _this.textLeft = wrapperWidth + "px";
    //     //         setTimeout(() => {
    //     //           textRoll();
    //     //         }, 0);
    //     //       }, againTime);
    //     //     }
    //   });
    // },
    textRoll() {
      this.textTransition = "left " + this.transTime + "s linear"; // 滚动前绑定过渡属性
      this.textLeft = -this.textWidth + "px"; // 向容器最左移动
      setTimeout(() => {
        // 还原文本位置
        this.textTransition = "none"; // 还原前需清除过渡
        this.textLeft = this.wrapperWidth + "px";
        setTimeout(() => {
          this.textRoll();
        }, 0);
      }, this.againTime);
    },
    startScroll() {
      nextTick(() => {
        // console.log("开始");
        var that = this;
        that.wrapperWidth = that.$refs.wrapper.clientWidth; // 容器的总宽度
        that.textWidth = that.$refs.text.clientWidth; // 文本的总宽度
        that.transTime = that.textWidth / 20; // 根据文本宽度设置过渡时间
        that.againTime = that.transTime * 1000 + 1000; // 重新开始滚动时间计算(动态)

        that.textLeft = that.wrapperWidth + "px"; // 开始滚动前设定文本在容器最右侧以外
        // console.log("开始");
        // console.log("容器的总宽度", that.wrapperWidth);
        // console.log("文本的总宽度", that.textWidth);
        // console.log("根据文本宽度设置过渡时间", that.transTime);
        // console.log("重新开始滚动时间计算", that.againTime);
        // console.log("开始滚动前设定文本在容器最右侧以外", that.textLeft);
        setTimeout(() => {
          that.textRoll();
        }, 0);
      });
    },
    stopScroll() {
      nextTick(() => {
        // console.log("结束");
        var that = this;
        that.wrapperWidth = that.$refs.wrapper.clientWidth; // 容器的总宽度
        that.textWidth = that.$refs.text.clientWidth * 1000; // 文本的总宽度
        that.transTime = that.textWidth / 20; // 根据文本宽度设置过渡时间
        that.againTime = (that.transTime + 1000) * 1000; // 重新开始滚动时间计算(动态)

        that.textLeft = that.wrapperWidth + "px"; // 开始滚动前设定文本在容器最右侧以外
        // console.log("结束");
        // console.log("容器的总宽度", that.wrapperWidth);
        // console.log("文本的总宽度", that.textWidth);
        // console.log("根据文本宽度设置过渡时间", that.transTime);
        // console.log("重新开始滚动时间计算", that.againTime);
        // console.log("开始滚动前设定文本在容器最右侧以外", that.textLeft);
        setTimeout(() => {
          that.textRoll();
        }, 0);
      });
    },
  },
  computed: {
    listTemp() {
      let index = 0;
      const count = 5;
      const arrTemp = [];
      const experts = this.slidessdanwei;
      for (let i = 0; i < this.slidessdanwei.length; i++) {
        index = parseInt(i / count);
        if (arrTemp.length <= index) {
          arrTemp.push([]);
        }
        arrTemp[index].push(experts[i]);
      }
      return arrTemp;
    },
    // 公告滚动自定义
    // defaultOption() {
    //   return {
    //     step: 1, // 数值越大速度滚动越快
    //     limitMoveNum: 4, // 开始无缝滚动的数据量 this.cityData.length
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 1, // 0向下 1向上 2向左 3向右
    //     openWatch: true, // 开启数据实时监控刷新dom
    //     singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    //   };
    // },
  },
};
</script>
<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/* ::v-deep(.el-carousel__container) {
  height: 450px !important;
} */
/* .el-carousel__container:nth-child(1) {
  height: 500px;
} */

/* >>> .el-carousel__item {
  background-color: #000000 !important;
} */
>>> .mmmm .el-carousel__container {
  height: 500px !important;
}
>>> .el-carousel__item {
  background-color: #ffffff !important;
}
</style>
<style scoped>
.mobile-marquee {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 10px 0px 0px 0;
  background: #1111110d;
  /* border-left: 6px #1e619c solid;
  border-right: 6px #1e619c solid; */
}

.mobile-marquee-wrapper {
  flex: 1;
  height: 40px;
  overflow: hidden;
  position: relative;
}

.mobile-marquee img {
  width: 14px;
  height: 12px;
  margin-right: 7px;
}

.mobile-marquee-text {
  color: #333;
  white-space: nowrap;
  line-height: 40px;
  position: absolute;
  font-size: 16px;
  letter-spacing: 2px;
}
</style>
<style scoped>
.seamless_scroll {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 20px;
}

.seamless_scroll .seamless_scroll_item {
  display: inline-block;
  margin-right: 10px;
}
</style>
